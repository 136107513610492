import React from "react"
import { Link, graphql } from "gatsby"
import Img from "gatsby-image"
import SEO from "../components/seo"
import "../styles/races.css"
import {monthNamesShort, pad} from '../components/names'


class BlogIndex extends React.Component {
 
  render() {

const regex = /(<([^>]+)>)/ig;

const { data } = this.props

const { currentPage, numPages } = this.props.pageContext
const isFirst = currentPage === 1
const isLast = currentPage === numPages
const prevPage = currentPage - 1 === 1 ? '/' : (currentPage - 1).toString()
const nextPage = (currentPage + 1).toString()


return(  
  <>
    <SEO title="Løb" />
    <section id="races">
        <h2 className="heading">Løb</h2>
        <section className="races--collection">
            {data.allDirectusRace.edges.map(edge => (
              <Link to={edge.node.race_url ? `/race/${new Date(edge.node.from).getFullYear()}/${pad(new Date(edge.node.from).getMonth() + 1)}/${edge.node.race_url}` : "/"} className="races--article-block" key={edge.node.id}>
                <article>
                    <Img fluid={edge.node.image.localFile.childImageSharp.fluid}/>
                    <h3>{`${edge.node.type.type_name} | ${edge.node.race_title}`}</h3>
                    <p className="races--article-time">{monthNamesShort[new Date(edge.node.from.replace(/-/g, '/')).getMonth()] + " " + new Date(edge.node.from.replace(/-/g, '/')).getDate() + ", " + new Date(edge.node.from.replace(/-/g, '/')).getFullYear()}</p>
                    <p dangerouslySetInnerHTML={{__html:edge.node.circuit.descr.replace(regex, '').length > 130 ? edge.node.circuit.descr.replace(regex, '').substring(0, 130).substr(0, Math.min(edge.node.circuit.descr.replace(regex, '').substring(0, 130).length, edge.node.circuit.descr.replace(regex, '').substring(0, 130).lastIndexOf(" "))) +" ..." : edge.node.circuit.descr.replace(regex, '')}}></p>
                    <span className="races--article-link">Læs om løbet</span>
                </article>
              </Link>
            ))}
        </section>
        <section className="pagination">
          {isFirst ? (<p className="previous">tilbage</p>) : (<Link to={`races/${prevPage}`} className="previous">tilbage</Link>)}
          <p className="number">{`${currentPage}/${numPages}`}</p>
          {isLast ? (<p className="next">næste</p>) : (<Link to={`races/${nextPage}`} className="next">næste</Link>)}
   
        </section>
    </section>
  </>
)}}

export default BlogIndex

export const query = graphql`
  query blogListQuery($skip: Int!, $limit: Int!) {
    allDirectusRace (
        filter: {status: {eq: "published"}}
        sort: { fields: from, order: DESC }
        limit: $limit
        skip: $skip
      ){
      edges {
        node {
          id
          image {
            localFile {
              childImageSharp {
                fluid(quality: 50, maxWidth: 500) {
                    ...GatsbyImageSharpFluid_withWebp              
                }
              }
            }
          }
          race_title
          race_url
          type {
            type_name
          }
          circuit {
            descr
          }
          from
        }
      }
    }
  }  
`
